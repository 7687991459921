<template>
  <v-row v-if="eventPreview.data != null">
    <v-col class="px-4">
      <v-list
        class="pa-0 cb-simple-list mt-2"
        dense
        outlined
      >
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon color="accent">{{eventPreview.icon}}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-col class="pa-0">
                <tr
                  v-for="(key,index) in Object.keys(eventPreview.data)"
                  :key="index"
                  class="mb-1"
                >
                  <td class="pr-2">{{key}}</td>
                  <td
                    :title="eventPreview.data[key]"
                    class="ml-2 font-weight-medium text-truncate"
                    style="max-width: 400px"
                    v-html="eventPreview.data[key]"
                  />
                </tr>
              </v-col>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: 'EventOverview',
  props: {
    event: Object
  },
  computed: {
    eventPreview() {
      let event = this.event
      let step_name = event.event_code

      let result = {
        icon: null,
        data: null
      }
      if (event.input_variables) {

        switch (step_name) {
          case 'communication_send_email.sent': {
            result.icon = 'email'
            result.data = {
              'From': event.input_variables.from_mail + ' (' + event.input_variables.from_name + ')',
              'To': event.input_variables.to_mail + ' (' + event.input_variables.to_name + ')',
              'Asunto': event.input_variables.subject,
            }
            break
          }
          case 'communication_send_sms.sent': {
            result.icon = 'sms'
            result.data = {
              'Teléfono': '+' + event.input_variables.country_code + ' ' + event.input_variables.phone_number,
              'Texto': event.input_variables.text,
            }
            break
          }
          case 'communication_send_postal.generated': {
            result.icon = 'description'
            result.data = {
              'Destinatario': event.input_variables.recipient_name,
              'Dirección': event.input_variables.recipient_address + '<br>' + event.input_variables.recipient_city + ', ' + event.input_variables.recipient_zipcode + '<br>'
                + event.input_variables.recipient_province
            }
            break
          }
        }
      }
      return result
    },
  }
}
</script>


