import helpers from '@/plugins/helpers'
import moment from 'moment'

export default Object.freeze({
  ENTITY_NAME: 'instance',
  NAME: 'name',
  CODE: 'instance_code',
  CREATION: 'creation_timestamp',
  DELETION: 'deletion_timestamp',
  MASTER_ACCOUNT_CODE: 'master_account_code',
  MASTER_ACCOUNT_NAME: 'master_account_name',
  SUB_ACCOUNT_CODE: 'subaccount_code',
  SUB_ACCOUNT_NAME: 'subaccount_name',
  PROCESS_CODE: 'process_code',
  PROCESS_NAME: 'process_name',
  MACROSTATUS: 'macrostatus',
  SOURCE: 'source',
  SOURCE_EXTRA: 'source_extra',
  IS_DELETED: 'deleted',
  TIMESTAMP_TYPE: 'timestamp_type',
  TIMESTAMP_VALUES: 'timestamp_values',
  CERTIFICATE_KEY: 'certificate_key',
  TEMPLATE_CODE: 'template_code',
  TEMPLATE_VERSION: 'template_version',
  POSTAMAIL_DATA: 'postamail_data',
  POSTAMAIL_IDS: 'postamail_ids',
  EVENTS: 'events',
  PROTOCOL_VERSION: 'protocol_version',
  MACROSTATUS_TIMESTAMP: 'macrostatus_timestamp',
  TERMINAL: 'terminal',
  INTERVENERS: 'interveners',
  STEP_PARAMETERS: 'step_parameters',
  MINIMIZED: 'minimized',

  INTROMISSIONS: 'intromissions',
  ENQUIRIES: 'enquiries',

  sortedEvents(events) {
    return events.sort((a, b) => (a.timestamp == b.timestamp) ? a.order > b.order : a.timestamp > b.timestamp ? 1 : -1)
  },
  checkEvents(events, search) {
    let dayEvents = {}

    if (!events) {
      return dayEvents
    }
    events.forEach(event => {
      let day = moment.unix(event.timestamp).format('dddd DD/MM/YY')
      let second = moment.unix(event.timestamp).format('HH:mm:ss')

      let filterEvent = false
      if (search != null) {
        filterEvent = event.evidence_id.toLowerCase().indexOf(search.toLowerCase()) === -1 &&
          event.event_code.toLowerCase().indexOf(search.toLowerCase()) === -1 &&
          event.step_event.toLowerCase().indexOf(search.toLowerCase()) === -1
      }

      if (!filterEvent) {
        if (helpers.nullOrEmpty(dayEvents[day])) {
          dayEvents[day] = {}
        }
        if (helpers.nullOrEmpty(dayEvents[day][second])) {
          dayEvents[day][second] = []
        }
        dayEvents[day][second].push(event)
      }
    })
    return dayEvents
  },
})
