<template>
  <v-container class="pa-0">
    <v-row>
      <ExplorerHeader
        :section="section"
        :sections="sections"
        @[$events.EXPLORER_CHANGE_SECTION]="changeSection"
      />
    </v-row>
    <v-row class="cb-view-container">
      <v-tabs-items
        v-model="section"
        class="cb-view-tabs cts-w-100"
      >
        <v-tab-item
          v-if="section == 'exclusion'"
          id="exclusion"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <exclusion-list-checker/>
        </v-tab-item>
        <v-tab-item
          v-if="section == 'domains'"
          id="domains"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <domains-checker/>
        </v-tab-item>

      </v-tabs-items>
    </v-row>
  </v-container>
</template>

<script>
import ExplorerHeader from '@/components/repository/explorers/ExplorerHeader'
import {
  P_COMMSERVICES_EXCLUSION_LIST
} from '@/constants/group_permissions'
import service_user from '@/services/service_user'
import ExclusionListChecker from "@/components/commservices/cheker/exclusionListChecker.vue";
import DomainsChecker from "@/components/commservices/cheker/domainsChecker.vue";

export default {
  name: 'Checkers',
  components: {
    DomainsChecker,
    ExclusionListChecker,
    ExplorerHeader
  },
  data() {
    return {
      section: null,
    }
  },
  computed: {

    sections() {
      let result = []

      if (service_user.checkGroupPermission(P_COMMSERVICES_EXCLUSION_LIST)) {
        result.push(
          {
            id: 'domains',
            name: 'Dominios',
            icon: 'web',
            perm: P_COMMSERVICES_EXCLUSION_LIST
          })
        result.push(
          {
            id: 'exclusion',
            name: 'Lista de exclusión',
            icon: 'email',
            perm: P_COMMSERVICES_EXCLUSION_LIST
          })
      }

      return result
    }
  },
  methods: {
    changeSection(new_section) {
      this.section = new_section
    }
  }
}
</script>


