<template>
  <v-container>
    <v-row>
      <v-col class="pr-4" cols="6">
        <title-model-a icon="search" title="Consulta de la lista de exclusión"/>
        <v-row align="center" class="my-4 px-1">
          <v-text-field v-model="email" class="mr-4" clearable hide-details outlined
                        placeholder="Email a consultar"></v-text-field>
          <button-icon-text-model-b :disabled="helpers.nullOrEmpty(email)" icon="search"
                                    text="Consultar"
                                    @click="searchInList"></button-icon-text-model-b>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <v-col v-if="emailResult" class="pa-1">
                                <span v-if="emailResult.error !=0">
                                    <v-icon class="mr-2" color="error">error</v-icon>
                                    Ha ocurrido un error al buscar en la lista de exclusión
                                </span>
              <v-row class="mb-2">
                <span class="cb-font-size-3">Email consultado: <strong>{{searchEmail}}</strong></span>
              </v-row>
              <v-simple-table v-if="emailResult.error == 0">
                <tbody>
                <tr>
                  <td>Resultado</td>
                  <td colspan="2">
                                        <span v-if="emailResult.extra.find" class="font-weight-medium">
                                            <v-icon class="mr-2" color="error">unsubscribe</v-icon>
                                            En la lista de exclusión</span>
                    <span v-else>
                                            <v-icon class="mr-2" color="success">visibility_off</v-icon>
                                            No encontrado en la lista de exclusión</span>
                  </td>
                </tr>
                <tr>
                  <td>Razón:</td>
                  <td>
                    <span v-if="emailResult.extra.reason">{{emailResult.extra.reason}}</span>
                    <span v-else>N/A</span>
                  </td>
                </tr>
                <tr>
                  <td>Fecha:</td>
                  <td>
                                            <span
                                              v-if="emailResult.extra.timestamp">{{emailResult.extra.timestamp | moment('DD/MM/YYYY HH:mm')}} ({{emailResult.extra.timestamp | moment('from')}})</span>
                    <span v-else>N/A</span>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="service_user.checkGroupPermission(P_COMMSERVICES_EXCLUSION_LIST_EDIT)" class="pl-4" cols="6">
        <title-model-a icon="local_post_office" title="Acciones sobre la lista de exclusión"/>
        <v-col class="px-1 pt-4">
          <v-row class="mb-2">
            <v-text-field v-model="emailToEdit" class="mr-4" clearable hide-details outlined
                          placeholder="Email de la acción"/>
          </v-row>
          <v-row v-if="action == 'add'" class="mb-2">
            <v-text-field v-model="reason" class="mr-4" clearable hide-details
                          outlined
                          placeholder="Razón"></v-text-field>
          </v-row>
          <v-row class="mb-2">
            <v-radio-group v-model="action" dense hide-details row style="margin-top: 0">
              <v-radio label="Añadir" value="add"></v-radio>
              <v-radio label="Borrar" value="delete"></v-radio>
            </v-radio-group>

          </v-row>
          <v-row>
            <button-icon-text-model-b :disabled="helpers.nullOrEmpty(emailToEdit)"
                                      :icon="action == 'add'?'add':'delete'"
                                      :text="action == 'add'?'Añadir':'Borrar'"
                                      class="mr-2"
                                      @click="launchAction"></button-icon-text-model-b>

          </v-row>
        </v-col>
        <v-row>
          <v-col>
            <v-card v-if="emailToEditResult" class="pa-4">
                            <span v-if="emailToEditResult.error == 0">
                             <span v-if="emailToEditResult.message == 'add_email'">
                                <v-icon class="mr-2" color="success">check</v-icon>
                                Email Añadido a la lista de exclusión
                            </span>
                            <span v-else-if="emailToEditResult.message == 'removed_exclusion_list_email'">
                                <v-icon class="mr-2" color="success">check</v-icon>
                                Email borrado de la lista de exclusión
                            </span>
                            </span>
              <span v-else>
                                <v-icon class="mr-2" color="error">error</v-icon>
                                Ha ocurrido un error al realizar la acción
                            </span>

            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import service_protocol from '@/services/service_protocol'
import TitleModelA from "@/components/repository/TitleModelA.vue";
import ButtonIconTextModelB from "@/components/repository/buttons/ButtonIconTextModelB.vue";
import helpers from "@/plugins/helpers";
import service_commservices from "@/services/service_commservices";
import service_user from "@/services/service_user";
import {P_COMMSERVICES_EXCLUSION_LIST_EDIT} from "@/constants/group_permissions";


export default {
  name: 'exclusionListChecker',
  computed: {
    service_user() {
      return service_user
    }
  },
  components: {ButtonIconTextModelB, TitleModelA},
  data() {
    return {
      P_COMMSERVICES_EXCLUSION_LIST_EDIT,
      helpers,
      service_protocol,
      email: '',
      searchEmail: '',
      emailResult: null,

      action: 'add',
      reason: '',
      emailToEdit: '',
      emailEdited: '',
      emailToEditResult: null,
    }
  },
  methods: {
    async searchInList() {
      const result = await service_commservices.exclusionListSearch(this.email)
      this.searchEmail = this.email
      this.emailResult = result.data
      this.email = null
    },
    async launchAction() {
      if (this.action == 'add') {
        await this.addToList()
      } else {
        await this.deleteEmail()
      }
    },
    async addToList() {
      const result = await service_commservices.exclusionListAdd(this.emailToEdit, this.reason)
      this.emailEdited = this.emailToEdit
      this.emailToEditResult = result.data
      this.emailToEdit = null
      this.reason = ''
    },
    async deleteEmail() {
      const result = await service_commservices.exclusionListDelete(this.emailToEdit)
      this.emailEdited = this.emailToEdit
      this.emailToEditResult = result.data
      this.emailToEdit = null
    }
  },
  filters: {}
}
</script>
